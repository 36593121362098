import Utility from "../../../shared/utility.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav.js";
import EncryptUtility from "../../utility/js/encrypt-utility.js";
import usbService from "../js/usb-services";
import moment from "moment";
import commonApiService from "@/components/common/js/common-api-service.js";
import html2pdf from "html2pdf.js";
import PrinterDetails from "../../common/printer-details";
export default {
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refreshOutlined: true,
      mainKey: 0,
      headerData: [],
      searchEventLog: "",
      eventLogList: [],
      eventCodeId: 0,
      trackingTypeCode: "",
      notesDetails: [],
      noLogEvent: false,
      eventRules: [],
      showAreHDDsDevice: false,
      showHowManyHDDs: false,
      showWipeDate: false,
      showWipeCertAttached: false,
      HDDsAvail: "",
      noHDDs: "",
      showStartDate: true,
      startDate: false,
      sDate: new Date().toISOString().split("T")[0],
      wipeCertAttched: "",
      showWipeSuccess: false,
      loadData: false,
      firstWipeSuccess: "",
      secondWipeSuccess: "",
      thirdWipeSuccess: "",
      fourthWipeSuccess: "",
      fifthWipeSuccess: "",
      firstUnwipedSNo: "",
      secondUnwipedSNo: "",
      thirdUnwipedSNo: "",
      fourthUnwipedSNo: "",
      fifthUnwipedSNo: "",
      showStagingLoc: false,
      showDialogDetailsBox: false,
      showOutboundLog: false,
      dialogBoxHeader: "",
      displayData: "",
      stagingLoc: "",
      showLPN: false,
      file: "",
      files: "",
      fileList: [],
      noFiles: true,
      showCertUpload: false,
      showSubmit: false,
      checkRulesAfter: "",
      showPassFail: false,
      selectedPassFail: "",
      passFailList: [],
      showRepairReason: false,
      selectedRepairReason: "",
      repairReasonList: [],
      showFailCode: false,
      selectedFailCode: "",
      failCodeList: [],
      showRepairDescrip: false,
      repairDescription: "",
      showRepairCost: false,
      selectedRepairCost: "",
      show1UnwipedSNo: false,
      show2UnwipedSNo: false,
      show3UnwipedSNo: false,
      show4UnwipedSNo: false,
      show5UnwipedSNo: false,
      lpnVal: "",
      showPurge: false,
      showRepairCostThres: false,
      repairCost: 0,
      selectedImaged: "",
      showRepairAction: false,
      selectedRepairAction: "",
      repairActionList: [],
      showRepairSuccess: false,
      selectedRepairSuccessful: "",
      repairSuccessList: [],
      showRepairNotSuccess: false,
      repairNotSuccess: "",
      showCosGrade: false,
      selectedCosGrade: "",
      cosGradeList: [],
      disableHDDsAvail: false,
      disabledNoHDDs: false,
      disableWipeCertAttched: false,
      excelName: "",
      totalRecords: 0,
      pdfName: "",
      disablePassFail: false,
      disableRepairCost: false,
      passFailLogValid: false,
      stagingLocLPNValid: false,
      cosGradeValid: false,
      HDDsValid: false,
      disableRepairSuccessful: false,
      currAssetAction: [],
      finalLabel: "",
      currentAssetBackGround: true,
      totalLotDetails: [],
      disableSubmit: false,
      purgeAssetDialog: false,
      inboundDetailsHeaders: [
        {
          text: "Sales Order No",
          align: "start",
          value: "sono",
          class: "primary customwhite--text",
          width: "10%",
        },
        {
          text: "Task Id",
          align: "start",
          value: "task_id",
          class: "primary customwhite--text",
          width: "8%",
        },
        {
          text: "Task Identifier",
          align: "start",
          value: "taskidentifier",
          class: "primary customwhite--text",
          width: "15%",
        },
        {
          text: "Asset Action",
          align: "start",
          value: "assetaction",
          class: "primary customwhite--text",
          width: "10%",
        },
        {
          text: "Request Number",
          align: "start",
          value: "request_number",
          class: "primary customwhite--text",
          width: "10%",
        },
        {
          text: "Request",
          value: "request",
          align: "start",
          class: "primary customwhite--text",
          width: "20%",
        },
        {
          text: "RITM",
          value: "ritm",
          align: "start",
          class: "primary customwhite--text",
          width: "8%",
        },
        {
          text: "Posted Date",
          value: "posted",
          align: "start",
          class: "primary customwhite--text",
          width: "20%",
        },
      ],
      outboundDataListHeaders: [
        {
          text: "View Outbound Request JSON",
          align: "start",
          value: "outboundJson",
          class: "primary customwhite--text",
          width: "20%",
        },
        {
          text: "USB Response JSON",
          value: "usb_response_json",
          class: "primary customwhite--text",
          width: "20%",
        },
        {
          text: "Created Date",
          value: "cdate",
          class: "primary customwhite--text",
          width: "30%",
        },
        {
          text: "User Name",
          value: "username",
          class: "primary customwhite--text",
          width: "30%",
        },
      ],
      inboundDataList: [],
      outboundDataList: [],
      repairCostList: [
        {
          text: "Yes - Over Threshold",
          value: 1,
        },
        {
          text: "No - Under Threshold",
          value: 0,
        },
      ],
      defaultList: [
        {
          text: "Yes",
          value: "Y",
        },
        {
          text: "No",
          value: "N",
        },
      ],
      wipeSucessfull: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      },
      noHDDList: [
        {
          text: "1",
          value: "1",
        },
        {
          text: "2",
          value: "2",
        },
        {
          text: "3",
          value: "3",
        },
        {
          text: "4",
          value: "4",
        },
        {
          text: "5",
          value: "5",
        },
      ],
      eventLogHeaders: [
        { text: "Event", value: "event_descrip", class: "primary customwhite--text" },
        { text: "Details", value: "info", class: "primary customwhite--text" },
        { text: "Log Date", value: "Event_Datetime", class: "primary customwhite--text" },
        { text: "Logged By", value: "Event_logged_by", class: "primary customwhite--text" },
      ],
      json_fields: {
        Event: "Event",
        Details: "info",
        "Log Date": "Event_Datetime",
        "Logged By": "Event_logged_by",
      },
      assetTrackingObj: {
        UserID: 0,
        EventCodeID: 0,
        TrackingTypeCode: "",
        main_key: 0,
        OnSetup: 0,
        PassFailResults: "",
        RepairSuccessfulAnswer: "",
        ThresholdAnswer: "",
        HDDInDeviceAnswer: "",
        WipeCertAttachedAnswer: "",
      },
      lpnRule: [
        (v) => (v || "").length <= 39 || "Max Length of 39 characters",
        (v) => !!v || "Field is required",
        (value) => /^[0-9]{1,3}(?:-[0-9]{3})*$/.test(value) || "Enter a correct format: 000-000-000-000",
      ],
      fileRules: [
        (files) => !files || !files.some((file) => file.size > 10e5) || "File size should be less than 10 MB!",
        (v) => !!v || "Field is required",
      ],
      submitObj: {
        user_key: 0,
        main_key: 0,
        u_event_code: "",
        u_repair_cost_over_threshold: 0,
        u_repair_cost: 0,
        u_repair_reason: "",
        u_damage_description: "",
        u_is_repair_successful: 0,
        u_unsuccessful_repair: "",
        u_imaged: 0,
        u_hdd_in_device: 0,
        u_hdd_quantity: 0,
        hdd_list: "",
        u_hdd_wiped_date: "",
        u_wipe_certificate_attached: 0,
        wipe_certs: "",
        passfail: "",
        failc_key: 0,
        repair_key: 0,
        cos_grade: "",
        lbl_size: "",
        printer_type: "",
        fromAPI: 0,
        lpn: "",
        bin: "",
        Files: "",
      },
      eventDetailsHeaders: [
        { text: "Event Name", value: "eventName", class: "primary customwhite--text" },
        { text: "Created Date", value: "cdate", class: "primary customwhite--text" },
        { text: "Created By", value: "username", class: "primary customwhite--text" },
        { text: "View Event Details", value: "viewEventDetails", class: "primary customwhite--text" },
      ],
      showPurgeUSB: false,
      showReset: false,
      eventDetails: [],
      event1Info: [],
      showEvent1Details: false,
      event2Info: [],
      showEvent2Details: false,
      event3Info: [],
      showEvent3Details: false,
      event4Info: [],
      showEvent4Details: false,
      hddInDevice: false,
      wipeCertAttached: false,
      repairCostOverThreshold2: false,
      isRepairSuccessful: false,
      isImaged: false,
      repairCostOverThreshold3: false,
      correctDevice: false,
      assetShipDamage: false,
      damagePicture: false,
      required: [(v) => !!v || "Field is required"],
      numberRule: [(v) => /^\d{1,13}(\.\d{1,2})?$/.test(v) || "Invalid Price", (v) => !!v || "Field is required"],
      max10Rule: [(v) => (v || "").length <= 10 || "Max Length of 10 character", (v) => !!v || "Field is required"],
      max200Rule: [(v) => (v || "").length <= 200 || "Max Length of 200 character", (v) => !!v || "Field is required"],
      max200NotReqRule: [(v) => (v || "").length <= 200 || "Max Length of 200 character"],
      primaryName: EncryptUtility.localStorageDecrypt("primaryName"),
      primaryLabelSize: EncryptUtility.localStorageDecrypt("primaryLabelSize"),
      primaryPrinterType: EncryptUtility.localStorageDecrypt("primaryPrinterType"),
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.mainPage = data?.mainPage;
    this.favouriteList = data?.favouriteList;
    this.pageUrl = data?.pageUrl;
    this.subPage = data?.subPage;
    this.showAdd = data?.showAdd;
    this.subPageUrl = data?.subPageUrl;
    this.appPageName = data?.appPageName;
    let actionData = await AddFavourite.getParentPageAction(this.userId, this.subPageUrl);
    this.actionList = actionData?.actionList;
    if (this.$route.params.mainKey !== undefined) {
      this.mainKey = atob(this.$route.params.mainKey);
      this.getAssetTrackingHeaderInfo();
      this.getAssetTrackingEventLog();
      this.getAssetTrackingNotes();
      this.getEventDetailHeader();
      this.actionShowReset();
      this.actionShowPurgeUSB();
    } else {
      this.$router.push({
        name: "USBAssetTracking",
        params: {
          submit: btoa("submitted"),
        },
      });
    }
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
  },
  mounted() {},
  methods: {
    //Reset Function
    resetFunction() {
      this.headerData = [];
      this.searchEventLog = "";
      this.eventLogList = [];
      this.notesDetails = [];
      this.eventCodeId = 0;
      this.trackingTypeCode = "";
      this.noLogEvent = false;
      this.eventRules = [];
      this.showAreHDDsDevice = false;
      this.showHowManyHDDs = false;
      this.showWipeDate = false;
      this.showWipeCertAttached = false;
      this.HDDsAvail = "";
      this.noHDDs = "";
      this.showStartDate = true;
      this.startDate = false;
      this.sDate = new Date().toISOString().split("T")[0];
      this.wipeCertAttched = "";
      this.showWipeSuccess = false;
      this.loadData = false;
      this.firstWipeSuccess = "";
      this.secondWipeSuccess = "";
      this.thirdWipeSuccess = "";
      this.fourthWipeSuccess = "";
      this.fifthWipeSuccess = "";
      this.firstUnwipedSNo = "";
      this.secondUnwipedSNo = "";
      this.thirdUnwipedSNo = "";
      this.fourthUnwipedSNo = "";
      this.fifthUnwipedSNo = "";
      this.showStagingLoc = false;
      this.stagingLoc = "";
      this.showLPN = false;
      this.file = "";
      this.files = "";
      this.fileList = [];
      this.noFiles = true;
      this.showCertUpload = false;
      this.showSubmit = false;
      this.checkRulesAfter = "";
      this.showPassFail = false;
      this.passFailList = [];
      this.selectedPassFail = "";
      this.showRepairReason = false;
      this.selectedRepairReason = "";
      this.repairReasonList = [];
      this.showFailCode = false;
      this.selectedFailCode = "";
      this.failCodeList = [];
      this.showRepairDescrip = false;
      this.repairDescription = "";
      this.showRepairCost = false;
      this.selectedRepairCost = "";
      this.showRepairCostThres = false;
      this.repairCost = 0;
      this.show1UnwipedSNo = false;
      this.show2UnwipedSNo = false;
      this.show3UnwipedSNo = false;
      this.show4UnwipedSNo = false;
      this.show5UnwipedSNo = false;
      this.lpnVal = "";
      this.showPurge = false;
      this.selectedImaged = "";
      this.showRepairAction = false;
      this.selectedRepairAction = "";
      this.repairActionList = [];
      this.showRepairSuccess = false;
      this.selectedRepairSuccessful = "";
      this.repairSuccessList = [];
      this.showRepairNotSuccess = false;
      this.repairNotSuccess = "";
      this.showCosGrade = false;
      this.selectedCosGrade = "";
      this.cosGradeList = [];
      this.disableHDDsAvail = false;
      this.disabledNoHDDs = false;
      this.disableWipeCertAttched = false;
      this.excelName = "";
      this.totalRecords = 0;
      this.pdfName = "";
      this.disablePassFail = false;
      this.disableRepairCost = false;
      this.passFailLogValid = false;
      this.stagingLocLPNValid = false;
      this.cosGradeValid = false;
      this.HDDsValid = false;
      this.disableRepairSuccessful = false;
      this.currAssetAction = [];
      this.totalLotDetails = [];
      this.primaryName = EncryptUtility.localStorageDecrypt("primaryName");
      this.primaryLabelSize = EncryptUtility.localStorageDecrypt("primaryLabelSize");
      this.primaryPrinterType = EncryptUtility.localStorageDecrypt("primaryPrinterType");
      this.finalLabel = "";
      this.currentAssetBackGround = false;
      this.disableSubmit = false;
      this.purgeAssetDialog = false;
      this.assetTrackingObj = {
        UserID: 0,
        EventCodeID: 0,
        TrackingTypeCode: "",
        main_key: 0,
        OnSetup: 0,
        PassFailResults: "",
        RepairSuccessfulAnswer: "",
        ThresholdAnswer: "",
        HDDInDeviceAnswer: "",
        WipeCertAttachedAnswer: "",
      };
      this.wipeSucessfull = {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      };
      this.submitObj = {
        user_key: 0,
        main_key: 0,
        u_event_code: "",
        u_repair_cost_over_threshold: 0,
        u_repair_cost: 0,
        u_repair_reason: "",
        u_damage_description: "",
        u_is_repair_successful: 0,
        u_unsuccessful_repair: "",
        u_imaged: 0,
        u_hdd_in_device: 0,
        u_hdd_quantity: 0,
        hdd_list: "",
        u_hdd_wiped_date: "",
        u_wipe_certificate_attached: 0,
        wipe_certs: "",
        passfail: "",
        failc_key: 0,
        repair_key: 0,
        cos_grade: "",
        lbl_size: "",
        printer_type: "",
        fromAPI: 0,
        lpn: "",
        bin: "",
        Files: "",
      };
      this.showPurgeUSB = false;
      this.showReset = false;
      this.eventDetails = [];
      this.event1Info = [];
      this.showEvent1Details = false;
      this.event2Info = [];
      this.showEvent2Details = false;
      this.event3Info = [];
      this.showEvent3Details = false;
      this.event4Info = [];
      this.showEvent4Details = false;
      this.hddInDevice = false;
      this.wipeCertAttached = false;
      this.repairCostOverThreshold2 = false;
      this.isRepairSuccessful = false;
      this.isImaged = false;
      this.repairCostOverThreshold3 = false;
      this.correctDevice = false;
      this.assetShipDamage = false;
      this.damagePicture = false;
      this.getAssetTrackingHeaderInfo();
      this.getAssetTrackingEventLog();
      this.getAssetTrackingNotes();
      this.getEventDetailHeader();
    },
    //Reset Log Event
    resetLogEvent() {
      if (this.showPassFail || this.showImaged) {
        this.$refs.passFailLogForm[0].resetValidation();
      } else if (this.showAreHDDsDevice) {
        this.$refs.HDDsForm[0].resetValidation();
      } else if (this.showCosGrade) {
        this.$refs.cosGradeForm[0].resetValidation();
      } else if (this.showStagingLoc) {
        this.$refs.stagingLocLPNForm[0].resetValidation();
      }
      this.eventRules = [];
      this.showAreHDDsDevice = false;
      this.showHowManyHDDs = false;
      this.showWipeDate = false;
      this.showWipeCertAttached = false;
      this.HDDsAvail = "";
      this.noHDDs = "";
      this.showStartDate = true;
      this.startDate = false;
      this.sDate = new Date().toISOString().split("T")[0];
      this.wipeCertAttched = "";
      this.showWipeSuccess = false;
      this.loadData = false;
      this.firstWipeSuccess = "";
      this.secondWipeSuccess = "";
      this.thirdWipeSuccess = "";
      this.fourthWipeSuccess = "";
      this.fifthWipeSuccess = "";
      this.firstUnwipedSNo = "";
      this.secondUnwipedSNo = "";
      this.thirdUnwipedSNo = "";
      this.fourthUnwipedSNo = "";
      this.fifthUnwipedSNo = "";
      this.showStagingLoc = false;
      this.showLPN = false;
      this.file = "";
      this.files = "";
      this.fileList = [];
      this.noFiles = true;
      this.showCertUpload = false;
      this.showSubmit = false;
      this.checkRulesAfter = "";
      this.showPassFail = false;
      this.passFailList = [];
      this.selectedPassFail = "";
      this.showRepairReason = false;
      this.selectedRepairReason = "";
      this.repairReasonList = [];
      this.showFailCode = false;
      this.selectedFailCode = "";
      this.failCodeList = [];
      this.showRepairDescrip = false;
      this.repairDescription = "";
      this.showRepairCost = false;
      this.selectedRepairCost = "";
      this.showRepairCostThres = false;
      this.repairCost = 0;
      this.show1UnwipedSNo = false;
      this.show2UnwipedSNo = false;
      this.show3UnwipedSNo = false;
      this.show4UnwipedSNo = false;
      this.show5UnwipedSNo = false;
      this.lpnVal = "";
      this.showPurge = false;
      this.selectedImaged = "";
      this.showRepairAction = false;
      this.selectedRepairAction = "";
      this.repairActionList = [];
      this.showRepairSuccess = false;
      this.selectedRepairSuccessful = "";
      this.repairSuccessList = [];
      this.showRepairNotSuccess = false;
      this.repairNotSuccess = "";
      this.showCosGrade = false;
      this.selectedCosGrade = "";
      this.cosGradeList = [];
      this.disableHDDsAvail = false;
      this.disabledNoHDDs = false;
      this.disableWipeCertAttched = false;
      this.excelName = "";
      this.totalRecords = 0;
      this.pdfName = "";
      this.disablePassFail = false;
      this.disableRepairCost = false;
      this.passFailLogValid = false;
      this.stagingLocLPNValid = false;
      this.cosGradeValid = false;
      this.HDDsValid = false;
      this.disableRepairSuccessful = false;
      this.currAssetAction = [];
      this.assetTrackingObj = {
        UserID: 0,
        EventCodeID: 0,
        TrackingTypeCode: "",
        main_key: 0,
        OnSetup: 0,
        PassFailResults: "",
        RepairSuccessfulAnswer: "",
        ThresholdAnswer: "",
        HDDInDeviceAnswer: "",
        WipeCertAttachedAnswer: "",
      };
      this.wipeSucessfull = {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
      };
      this.submitObj = {
        user_key: 0,
        main_key: 0,
        u_event_code: "",
        u_repair_cost_over_threshold: 0,
        u_repair_cost: 0,
        u_repair_reason: "",
        u_damage_description: "",
        u_is_repair_successful: 0,
        u_unsuccessful_repair: "",
        u_imaged: 0,
        u_hdd_in_device: 0,
        u_hdd_quantity: 0,
        hdd_list: "",
        u_hdd_wiped_date: "",
        u_wipe_certificate_attached: 0,
        wipe_certs: "",
        passfail: "",
        failc_key: 0,
        repair_key: 0,
        cos_grade: "",
        lbl_size: "",
        printer_type: "",
        fromAPI: 0,
        lpn: "",
        bin: "",
        Files: "",
      };
      this.assetTrackingEventRules();
    },
    //Show Reset button
    actionShowReset() {
      if (!this.showPurge && !this.noLogEvent) {
        this.showReset = true;
      } else {
        this.showReset = false;
      }
    },
    //Show purge in breadcrumb
    actionShowPurgeUSB() {
      if (this.actionSetter("PurgeAssetTrackingRecords")) {
        this.showPurgeUSB = true;
      } else {
        this.showPurgeUSB = false;
      }
    },
    //Get Header Info
    async getAssetTrackingHeaderInfo() {
      let data = await usbService.getAssetTrackingHeaderInfo("get", this.userId, this.mainKey);
      if (data !== undefined) {
        if (data.message !== "NA") {
          const newArr = data?.map((obj) => {
            return {
              ...obj,
              Receive_date: obj.Receive_date == "1900-01-01T00:00:00" ? "" : Utility.convertESTToLocal(obj.Receive_date),
              ShipDate: obj.ShipDate == "1900-01-01T00:00:00" ? "" : Utility.convertESTToLocal(obj.ShipDate),
            };
          });
          this.headerData = newArr;
          this.eventCodeId = this.headerData[0]?.CurrentEventCodeID;
          this.trackingTypeCode = this.headerData[0]?.TrackingTypeCode;
          this.showPurge = this.headerData[0]?.CanPurge;
          this.xUSBScan(this.headerData[0].serialno, this.headerData[0].DeviceLocation);
          if (this.headerData[0]?.HamAssetResponse.length !== 0 && this.eventCodeId == "6") {
            this.submitObj.u_event_code = "7";
            this.submitEventsData();
          } else {
            this.submitObj.u_event_code = "3";
          }
          this.assetTrackingEventRules();
          this.getInboundDetails();
          this.getOutboundDetails();
        }
      }
    },
    xUSBScan(serialNo, location) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.axios
        .get("ex/x_usb_scan?serial_number=" + serialNo + "&location=" + location)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData !== "" && responseData !== undefined) {
              let firstParse = JSON.parse(responseData?.body);
              if (firstParse !== "" && firstParse !== undefined) {
                let secondParse = JSON.parse(firstParse);
                this.currAssetAction = secondParse.result;
                let assetAction = this.currAssetAction.asset_action.toUpperCase();
                if (this.headerData[0]?.TrackingTypeName !== assetAction) {
                  this.currentAssetBackGround = false;
                } else {
                  this.currentAssetBackGround = true;
                }
              } else {
                this.currAssetAction = "";
              }
            } else {
              this.currAssetAction = "";
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get Event Log
    async getAssetTrackingEventLog() {
      let data = await usbService.getAssetTrackingEventLog("get", this.userId, this.mainKey);
      if (data !== undefined) {
        if (data?.message !== "NA") {
          const newArr = data?.map((obj) => {
            return {
              ...obj,
              Event_Datetime: Utility.convertESTToLocal(obj.Event_Datetime),
            };
          });
          this.eventLogList = newArr;
          this.totalRecords = newArr.length;
        }
      }
    },
    //Get Notes
    async getAssetTrackingNotes() {
      let data = await usbService.getAssetTrackingNotes("get", this.userId, this.mainKey);
      if (data !== undefined) {
        if (data.message !== "NA" && data?.Resultset !== "") {
          const newArr = data?.Resultset.map((obj) => {
            return {
              ...obj,
              RepairDate: Utility.convertESTToLocal(obj.RepairDate),
            };
          });
          this.notesDetails = newArr;
        }
      }
    },
    //Get Event Details Header
    async getEventDetailHeader() {
      let data = await usbService.getEventDetailHeader("get", this.userId, this.mainKey);
      if (data !== undefined) {
        if (data.message !== "NA") {
          const newArr = data?.map((obj) => {
            return {
              ...obj,
              cdate: Utility.convertESTToLocal(obj.cdate),
            };
          });
          this.eventDetails = newArr;
        }
      }
    },
    //Event Details Information
    async eventDetailsInfo(item) {
      let obj = {
        UserId: parseInt(this.userId),
        main_key: parseInt(this.mainKey),
        eventypeid: parseInt(item.eventTypeId),
        eventid: parseInt(item.eventid),
      };
      let showMsg = false;
      let data = await usbService.eventDetailsInfo("post", obj, showMsg);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.event1Info = [];
          this.showEvent1Details = false;
          this.event2Info = [];
          this.showEvent2Details = false;
          this.event3Info = [];
          this.showEvent3Details = false;
          this.event4Info = [];
          this.showEvent4Details = false;
          this.hddInDevice = false;
          this.wipeCertAttached = false;
          this.repairCostOverThreshold2 = false;
          this.isRepairSuccessful = false;
          this.isImaged = false;
          this.repairCostOverThreshold3 = false;
          this.correctDevice = false;
          this.assetShipDamage = false;
          this.damagePicture = false;
          if (item.eventTypeId == 1) {
            this.event1Info = data;
            this.showEvent1Details = true;
            this.hddInDevice = data[0].u_hdd_in_device == "Yes" ? true : false;
            this.wipeCertAttached = data[0].u_wipe_certificate_attached == "Yes" ? true : false;
          }
          if (item.eventTypeId == 2) {
            this.event2Info = data;
            this.showEvent2Details = true;
            this.repairCostOverThreshold2 = data[0].u_repair_cost_over_threshold == "Yes" ? true : false;
          }
          if (item.eventTypeId == 3) {
            this.event3Info = data;
            this.showEvent3Details = true;
            this.isRepairSuccessful = data[0].u_is_repair_successful == "Yes" ? true : false;
            this.isImaged = data[0].u_imaged == "Yes" ? true : false;
            this.repairCostOverThreshold3 = data[0].u_repair_cost_over_threshold == "Yes" ? true : false;
          }
          if (item.eventTypeId == 4) {
            this.event4Info = data;
            this.showEvent4Details = true;
            this.correctDevice = data[0].u_correct_device == "Yes" ? true : false;
            this.assetShipDamage = data[0].u_asset_ship_damage == "Yes" ? true : false;
            this.damagePicture = data[0].u_damage_picture == "Yes" ? true : false;
          }
        }
      }
    },
    //Check event rules
    async assetTrackingEventRules() {
      this.assetTrackingObj.UserID = parseInt(this.userId);
      this.assetTrackingObj.EventCodeID = parseInt(this.eventCodeId);
      this.assetTrackingObj.TrackingTypeCode = this.trackingTypeCode;
      this.assetTrackingObj.main_key = parseInt(this.mainKey);
      this.assetTrackingObj.OnSetup = 1;
      let showMsg = false;
      let data = await usbService.assetTrackingEventRules("post", this.assetTrackingObj, showMsg);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.eventRules = data;
          this.checkRulesAfter = data[0].CheckRulesAfter;
          this.showAreHDDsDevice = data[0].HDDInDevice;
          this.showImaged = data[0].Imaged;
          this.noLogEvent = false;
          this.loadData = true;
          if (data[0]?.PassFail?.Results?.length !== 0 && data[0]?.PassFail?.Results !== undefined) {
            this.showPassFail = true;
            this.passFailList = data[0]?.PassFail?.Results;
          } else {
            this.showPassFail = false;
            this.passFailList = [];
          }
          if (data[0]?.RepairSuccessful?.RepairSuccessful?.length !== 0 && data[0]?.RepairSuccessful?.RepairSuccessful !== undefined) {
            this.showRepairSuccess = true;
            this.repairSuccessList = data[0]?.RepairSuccessful?.RepairSuccessful;
          } else {
            this.showRepairSuccess = false;
            this.repairSuccessList = [];
          }
          if (data[0]?.RepairAction[0]?.RepairAction?.length !== 0 && data[0]?.RepairAction[0]?.RepairAction !== undefined) {
            this.showRepairAction = true;
            this.repairActionList = data[0]?.RepairAction[0]?.RepairAction;
          } else {
            this.showRepairAction = false;
            this.repairActionList = [];
          }
        } else {
          this.eventRules = [];
          this.noLogEvent = true;
          this.loadData = true;
        }
      }
    },
    //Was Repair Successful
    async changeWasRepairSuccess() {
      if (this.selectedRepairSuccessful !== "--- Select ---") {
        if (this.$refs.passFailLogForm[0].validate()) {
          this.assetTrackingObj.UserID = parseInt(this.userId);
          this.assetTrackingObj.EventCodeID = parseInt(this.eventCodeId);
          this.assetTrackingObj.TrackingTypeCode = this.trackingTypeCode;
          this.assetTrackingObj.main_key = parseInt(this.mainKey);
          this.assetTrackingObj.OnSetup = 0;
          this.assetTrackingObj.RepairSuccessfulAnswer = this.selectedRepairSuccessful;
          let showMsg = false;
          let data = await usbService.assetTrackingEventRules("post", this.assetTrackingObj, showMsg);
          if (data !== undefined) {
            if (data.message !== "NA") {
              this.disableRepairSuccessful = true;
              this.showRepairNotSuccess = data[0].WhyRepairNotSuccessful;
              this.showRepairCostThres = data[0].Threshold;
              this.showStagingLoc = data[0].SubmitButton;
              this.showLPN = data[0].SubmitButton;
              this.showSubmit = data[0].SubmitButton;
            }
          }
        } else {
          this.showRepairSuccess = false;
          this.selectedRepairSuccessful = "";
          this.$nextTick(() => {
            this.showRepairSuccess = true;
            this.selectedRepairSuccessful = "";
          });
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select Repair Successful?",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //on Change details
    onClickDetailsView(text, item) {
      this.showDialogDetailsBox = true;
      if (text == "InboundJSON") {
        this.dialogBoxHeader = "View Inbound Request JSON";
        this.displayData = item.request_json;
      } else if (text == "InboundXML") {
        this.dialogBoxHeader = "View Inbound Request XML";
        this.displayData = item.request_xml.replaceAll(">", ">\n");
      } else if (text == "OutboundXML") {
        this.dialogBoxHeader = "View Outbound Request XML";
        this.displayData = item.replaceAll(">", ">\n");
      } else if (text == "OutboundJSON") {
        this.dialogBoxHeader = "View Outbound Request JSON";
        this.displayData = item.rlp_outbound_json;
      } else if (text == "outboundLog") {
        this.outboundData.push(item);
        this.dialogBoxHeader = "View Outbound Response";
        this.showOutboundLog = true;
      }
    },
    // get Inbound Details
    async getInboundDetails() {
      let inboundDetails = await usbService.getInboundDetailsService("get", this.userId, this.mainKey);
      inboundDetails.forEach((x) => (x.posted = Utility.convertESTToLocal(x.posted)));
      this.inboundDataList = inboundDetails;
    },
    // get Inbound Details
    async getOutboundDetails() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/usb/getOutBoundLogEvents?UserID=` + this.userId + `&main_key=` + this.mainKey)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body;
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
                cdate: Utility.convertESTToLocal(obj.cdate),
              };
            });
            this.outboundDataList = newArr;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Change of Pass Fail
    async changePassFail() {
      if (this.selectedPassFail !== "") {
        this.assetTrackingObj.UserID = parseInt(this.userId);
        this.assetTrackingObj.EventCodeID = parseInt(this.eventCodeId);
        this.assetTrackingObj.TrackingTypeCode = this.trackingTypeCode;
        this.assetTrackingObj.main_key = parseInt(this.mainKey);
        this.assetTrackingObj.OnSetup = 0;
        this.assetTrackingObj.PassFailResults = this.selectedPassFail;
        let showMsg = false;
        let data = await usbService.assetTrackingEventRules("post", this.assetTrackingObj, showMsg);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.disablePassFail = true;
            this.showRepairDescrip = data[0]?.RepairDescription;
            this.showRepairCostThres = data[0].Threshold;
            this.showStagingLoc = data[0].SubmitButton;
            this.showLPN = data[0].SubmitButton;
            this.showSubmit = data[0].SubmitButton;
            if (data[0]?.RepairReason?.RepairReasons?.length !== 0 && data[0]?.RepairReason?.RepairReasons !== undefined) {
              this.showRepairReason = true;
              this.repairReasonList = data[0]?.RepairReason?.RepairReasons;
            } else {
              this.showRepairReason = false;
              this.repairReasonList = [];
            }
            if (data[0]?.Failcode[0]?.Failcode?.length !== 0 && data[0]?.Failcode[0]?.Failcode !== undefined) {
              this.showFailCode = true;
              this.failCodeList = data[0]?.Failcode[0]?.Failcode;
            } else {
              this.showFailCode = false;
              this.failCodeList = [];
            }
            if (data[0]?.CosGrade?.CosGrade.length !== 0 && data[0]?.CosGrade?.CosGrade !== undefined) {
              this.showCosGrade = true;
              this.disableSubmit = true;
              this.cosGradeList = data[0]?.CosGrade?.CosGrade;
            } else {
              this.showCosGrade = false;
              this.cosGradeList = [];
            }
          }
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select Pass/Fail",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Change in Repair Reason
    changeRepairReason() {
      if (this.showRepairReason && this.selectedRepairReason == "--- Select Repair Reason ---") {
        this.showRepairCostThres = false;
        this.selectedRepairCost = "";
        this.$nextTick(() => {
          this.showRepairCostThres = true;
          this.selectedRepairCost = "";
        });
        this.disableRepairCost = true;
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select Repair Reason",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      } else {
        this.disableRepairCost = false;
      }
    },
    //Change in repair cost over threshold
    async changeRepairCostThres() {
      if (this.$refs.passFailLogForm[0].validate()) {
        this.assetTrackingObj.UserID = parseInt(this.userId);
        this.assetTrackingObj.EventCodeID = parseInt(this.eventCodeId);
        this.assetTrackingObj.TrackingTypeCode = this.trackingTypeCode;
        this.assetTrackingObj.main_key = parseInt(this.mainKey);
        this.assetTrackingObj.OnSetup = 0;
        this.assetTrackingObj.PassFailResults = "";
        this.assetTrackingObj.ThresholdAnswer = this.selectedRepairCost == 1 ? "Y" : "N";
        this.assetTrackingObj.RepairSuccessfulAnswer = "";
        let showMsg = false;
        let data = await usbService.assetTrackingEventRules("post", this.assetTrackingObj, showMsg);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.disableRepairCost = true;
            this.showRepairCost = data[0].RepairCost;
            this.showStagingLoc = true;
            this.showLPN = true;
            this.showSubmit = true;
          }
        }
      } else {
        this.showRepairCostThres = false;
        this.selectedRepairCost = "";
        this.$nextTick(() => {
          this.showRepairCostThres = true;
          this.selectedRepairCost = "";
        });
      }
    },
    //Change event of Available HDDs
    async changeAvailHDDs() {
      this.assetTrackingObj.UserID = parseInt(this.userId);
      this.assetTrackingObj.EventCodeID = parseInt(this.eventCodeId);
      this.assetTrackingObj.TrackingTypeCode = this.trackingTypeCode;
      this.assetTrackingObj.main_key = parseInt(this.mainKey);
      this.assetTrackingObj.OnSetup = 0;
      this.assetTrackingObj.HDDInDeviceAnswer = this.HDDsAvail;
      let showMsg = false;
      let data = await usbService.assetTrackingEventRules("post", this.assetTrackingObj, showMsg);
      if (data !== undefined) {
        if (data.message !== "NA") {
          this.disableHDDsAvail = true;
          this.showHowManyHDDs = data[0].HDDQty;
          this.showWipeDate = data[0].WipeDate;
          this.showWipeCertAttached = data[0].WipeCertAttached;
          this.showSubmit = data[0].SubmitButton;
          this.showStagingLoc = data[0].SubmitButton;
          this.showLPN = data[0].SubmitButton;
        }
      }
    },
    //No of HDD selected
    changeNoHDD() {
      let number = parseInt(this.noHDDs);
      for (let i = 0; i <= number; i++) {
        this.wipeSucessfull[i] = true;
      }
      this.showWipeSuccess = true;
      this.disabledNoHDDs = true;
    },
    //Wipe Certificate attached
    async changeWipeCertAttach() {
      if (this.$refs.HDDsForm[0].validate()) {
        this.assetTrackingObj.UserID = parseInt(this.userId);
        this.assetTrackingObj.EventCodeID = parseInt(this.eventCodeId);
        this.assetTrackingObj.TrackingTypeCode = this.trackingTypeCode;
        this.assetTrackingObj.main_key = parseInt(this.mainKey);
        this.assetTrackingObj.OnSetup = 0;
        this.assetTrackingObj.HDDInDeviceAnswer = "";
        this.assetTrackingObj.WipeCertAttachedAnswer = this.wipeCertAttched;
        let showMsg = false;
        let data = await usbService.assetTrackingEventRules("post", this.assetTrackingObj, showMsg);
        if (data !== undefined) {
          if (data.message !== "NA") {
            this.disableWipeCertAttched = true;
            this.showCertUpload = data[0].WipeUpload;
            this.showSubmit = data[0].SubmitButton;
            this.showStagingLoc = data[0].SubmitButton;
            this.showLPN = data[0].SubmitButton;
          }
        }
      } else {
        this.showWipeCertAttached = false;
        this.wipeCertAttched = "";
        this.$nextTick(() => {
          this.showWipeCertAttached = true;
          this.wipeCertAttched = "";
        });
      }
    },
    //Format of LPN
    lpnNumFormat(event, num) {
      this.lpnVal = Utility.formatLPNNumber(event, num);
    },
    //Get next available LPN number
    async getLPNNumber() {
      let lpnData = await commonApiService.getNextAvailableLPN("get", this.userId);
      if (lpnData.message !== "NA") {
        this.lpnVal = lpnData[0]?.lpn;
      }
    },
    //Submit event
    async submitEventsData() {
      this.submitObj.user_key = parseInt(this.userId);
      this.submitObj.main_key = parseInt(this.mainKey);
      this.submitObj.lbl_size = EncryptUtility.localStorageDecrypt("primaryLabelSize");
      this.submitObj.printer_type = EncryptUtility.localStorageDecrypt("primaryPrinterType");
      this.submitObj.fromAPI = 0;
      this.submitObj.u_repair_cost_over_threshold = this.selectedRepairCost == "" ? 0 : parseInt(this.selectedRepairCost);
      this.submitObj.u_repair_cost = parseFloat(this.repairCost);
      this.submitObj.u_repair_reason = this.selectedRepairReason;
      this.submitObj.u_damage_description = this.repairDescription;
      this.submitObj.u_is_repair_successful = this.selectedRepairSuccessful == "Yes" ? 1 : 0;
      this.submitObj.u_unsuccessful_repair = this.repairNotSuccess;
      this.submitObj.u_imaged = this.selectedImaged == "Y" ? 1 : 0;
      this.submitObj.u_hdd_in_device = this.HDDsAvail == "Y" ? 1 : 0;
      this.submitObj.u_hdd_wiped_date = this.sDate == "" ? 0 : this.sDate;
      this.submitObj.u_wipe_certificate_attached = this.wipeCertAttched == "Y" ? 1 : 0;
      this.submitObj.passfail = this.selectedPassFail;
      this.submitObj.failc_key = this.selectedFailCode == "" ? 0 : parseInt(this.selectedFailCode);
      this.submitObj.repair_key = this.selectedRepairAction == "" ? 0 : parseInt(this.selectedRepairAction);
      this.submitObj.cos_grade = this.selectedCosGrade;
      this.submitObj.u_hdd_quantity = this.noHDDs == "" ? 0 : parseInt(this.noHDDs);
      this.submitObj.wipe_certs = "";
      this.submitObj.hdd_list = JSON.stringify({
        hdd_list: {
          u_hdd_wipe_1: (() => {
            if (this.firstWipeSuccess == "Y") {
              return "Yes";
            } else if (this.firstWipeSuccess == "N") {
              return "No";
            } else {
              return "";
            }
          })(),
          u_hdd_wipe_2: (() => {
            if (this.secondWipeSuccess == "Y") {
              return "Yes";
            } else if (this.secondWipeSuccess == "N") {
              return "No";
            } else {
              return "";
            }
          })(),
          u_hdd_wipe_3: (() => {
            if (this.thirdWipeSuccess == "Y") {
              return "Yes";
            } else if (this.thirdWipeSuccess == "N") {
              return "No";
            } else {
              return "";
            }
          })(),
          u_hdd_wipe_4: (() => {
            if (this.fourthWipeSuccess == "Y") {
              return "Yes";
            } else if (this.fourthWipeSuccess == "N") {
              return "No";
            } else {
              return "";
            }
          })(),
          u_hdd_wipe_5: (() => {
            if (this.fifthWipeSuccess == "Y") {
              return "Yes";
            } else if (this.fifthWipeSuccess == "N") {
              return "No";
            } else {
              return "";
            }
          })(),
          u_hdd_serial_number_1: this.firstUnwipedSNo,
          u_hdd_serial_number_2: this.secondUnwipedSNo,
          u_hdd_serial_number_3: this.thirdUnwipedSNo,
          u_hdd_serial_number_4: this.fourthUnwipedSNo,
          u_hdd_serial_number_5: this.fifthUnwipedSNo,
        },
      });
      if (this.showCosGrade) {
        if (this.$refs.cosGradeForm[0].validate()) {
          if (this.$refs.stagingLocLPNForm[0].validate()) {
            const formData = new FormData();
            this.submitObj.lpn = this.lpnVal;
            this.submitObj.bin = this.stagingLoc;
            formData.append("additionalData", JSON.stringify(this.submitObj));
            console.log("formData-919", formData);
            let showMsg = true;
            let data = await usbService.assetTrackingEventSubmit("post", formData, showMsg);
            if (data !== undefined) {
              if (data.message !== "NA") {
                console.log("cosGrade - data", data);
                if (data !== "" && data !== undefined) {
                  PrinterDetails.printLabelData(this.finalLabel, this.primaryName);
                }
                this.$router.push({
                  name: "USBAssetTracking",
                  params: {
                    submit: btoa("submitted"),
                  },
                });
              }
            }
          }
        }
      } else if (this.showRepairCost) {
        if (this.repairCost > 0) {
          if (this.$refs.stagingLocLPNForm[0].validate()) {
            const formData = new FormData();
            this.submitObj.lpn = this.lpnVal;
            this.submitObj.bin = this.stagingLoc;
            formData.append("additionalData", JSON.stringify(this.submitObj));
            console.log("formData-940", formData);
            let showMsg = true;
            let data = await usbService.assetTrackingEventSubmit("post", formData, showMsg);
            if (data !== undefined) {
              if (data.message !== "NA") {
                console.log("Repair Cost - data", data);
                if (data !== "" && data !== undefined) {
                  PrinterDetails.printLabelData(this.finalLabel, this.primaryName);
                }
                this.$router.push({
                  name: "USBAssetTracking",
                  params: {
                    submit: btoa("submitted"),
                  },
                });
              }
            }
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please enter any repair cost",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else if (this.showRepairNotSuccess) {
        if (this.$refs.stagingLocLPNForm[0].validate()) {
          const formData = new FormData();
          this.submitObj.lpn = this.lpnVal;
          this.submitObj.bin = this.stagingLoc;
          formData.append("additionalData", JSON.stringify(this.submitObj));
          console.log("formData-969", formData);
          let showMsg = true;
          let data = await usbService.assetTrackingEventSubmit("post", formData, showMsg);
          if (data !== undefined) {
            if (data.message !== "NA") {
              console.log("Repair Not Success - data", data);
              if (data !== "" && data !== undefined) {
                PrinterDetails.printLabelData(this.finalLabel, this.primaryName);
              }
              this.$router.push({
                name: "USBAssetTracking",
                params: {
                  submit: btoa("submitted"),
                },
              });
            }
          }
        }
      } else if (this.showCertUpload) {
        if (this.fileList.length !== 0) {
          if (this.$refs.stagingLocLPNForm[0].validate()) {
            const formData = new FormData();
            for (var x = 0; x < this.fileList.length; x++) formData.append("files", this.fileList[x]);
            this.submitObj.lpn = this.lpnVal;
            this.submitObj.bin = this.stagingLoc;
            formData.append("additionalData", JSON.stringify(this.submitObj));
            console.log("formData-999", formData);
            let showMsg = true;
            let data = await usbService.assetTrackingEventSubmit("post", formData, showMsg);
            if (data !== undefined) {
              if (data.message !== "NA") {
                console.log("Cret Upload - data", data);
                if (data !== "" && data !== undefined) {
                  PrinterDetails.printLabelData(data, this.primaryName);
                }
                this.$router.push({
                  name: "USBAssetTracking",
                  params: {
                    submit: btoa("submitted"),
                  },
                });
              }
            }
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please upload at least one file",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        if (this.$refs.stagingLocLPNForm[0].validate()) {
          this.submitObj.lpn = this.lpnVal;
          this.submitObj.bin = this.stagingLoc;
          const formData = new FormData();
          formData.append("additionalData", JSON.stringify(this.submitObj));
          console.log("formData-1027", formData);
          let showMsg = true;
          let data = await usbService.assetTrackingEventSubmit("post", formData, showMsg);
          if (data !== undefined) {
            if (data.message !== "NA") {
              console.log("Default - data", data);
              if (data !== "" && data !== undefined) {
                PrinterDetails.printLabelData(data, this.primaryName);
              }
              this.$router.push({
                name: "USBAssetTracking",
                params: {
                  submit: btoa("submitted"),
                },
              });
            }
          }
        }
      }
    },
    //Purge data button click event
    async purgeEventData() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let obj = {
        UserId: parseInt(this.userId),
        main_key: parseInt(this.mainKey),
      };
      this.axios
        .post("/usb/assettracking_purge", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.$router.push({
              name: "USBAssetTracking",
              params: {
                submit: btoa("submitted"),
              },
            });
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //To export the Excel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        this.excelName =
          "USB_Asset_Tracking_Details_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.get(`/usb/assettracking_event_log?UserID=${this.userId}&main_key=${this.mainKey}`);
        let responseData = JSON.parse(response.data.body.message);
        const newArr = responseData.map((obj) => {
          return {
            ...obj,
            Event_Datetime: Utility.convertESTToLocal(obj.Event_Datetime),
          };
        });
        this.export50kData = false;
        return newArr;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Export pdf
    printDetails() {
      this.startDownload();
      let response = this.eventLogList;
      response.forEach((item) => {
        delete item["HasComment"];
        delete item["EventID"];
      });
      this.totalLotDetails = [];
      this.totalLotDetails = this.buildHtmlTable(response);
      this.pdfName =
        "USB_Asset_Tracking_Details_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(this.totalLotDetails, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      });
      this.finishDownload();
    },
    buildHtmlTable(response) {
      this.tblTag = document.createElement("table");
      this.trTag = document.createElement("tr");
      this.thTag = document.createElement("th");
      this.tdTag = document.createElement("td");
      let table = this.tblTag.cloneNode(false);
      table.setAttribute("cellpadding", "0");
      table.setAttribute("cellspacing", "0");
      table.style.border = "1px solid black";
      table.style.borderRight = "none";
      table.style.borderTop = "none";
      let columns = this.addAllColumnHeaders(response, table);
      columns = columns.filter((column) => column !== "tid");
      for (var i = 0, maxi = response.length; i < maxi; ++i) {
        var tr = this.trTag.cloneNode(false);
        for (var j = 0, maxj = columns.length; j < maxj; ++j) {
          var td = this.tdTag.cloneNode(false);
          td.style.border = "1px solid black";
          td.style.padding = "10px";
          td.style.borderLeft = "none";
          td.style.borderBottom = "none";
          var cellValue = response[i][columns[j]];
          td.appendChild(document.createTextNode(cellValue || ""));
          tr.appendChild(td);
        }
        table.appendChild(tr);
      }
      return table;
    },
    addAllColumnHeaders(response, table) {
      let columnSet = [];
      let tr = this.trTag.cloneNode(false);
      for (var i = 0, l = response.length; i < l; i++) {
        for (var key in response[i]) {
          if (columnSet.indexOf(key) === -1) {
            if (key != "tid") {
              columnSet.push(key);
              var th = this.thTag.cloneNode(false);
              th.style.border = "1px solid black";
              th.setAttribute("padding", "5px");
              th.style.borderLeft = "none";
              th.style.borderBottom = "none";
              th.style.backgroundColor = "#005ba8";
              th.style.color = "white";
              if (key == "Event") key = "Event";
              if (key == "info") key = "Details";
              if (key == "Event_Datetime") key = "Log Date";
              if (key == "Event_logged_by") key = "Logged By";
              th.appendChild(document.createTextNode(key));
              tr.appendChild(th);
            }
          }
        }
      }
      table.appendChild(tr);
      return columnSet;
    },
    //Change in First Wipe
    changeFirstWipe() {
      if (this.firstWipeSuccess == "N") {
        this.show1UnwipedSNo = true;
      } else {
        this.show1UnwipedSNo = false;
      }
    },
    //Change in Second Wipe
    changeSecondWipe() {
      if (this.secondWipeSuccess == "N") {
        this.show2UnwipedSNo = true;
      } else {
        this.show2UnwipedSNo = false;
      }
    },
    //Change in Third Wipe
    changeThirdWipe() {
      if (this.thirdWipeSuccess == "N") {
        this.show3UnwipedSNo = true;
      } else {
        this.show3UnwipedSNo = false;
      }
    },
    //Change in Fourth Wipe
    changeFourthWipe() {
      if (this.fourthWipeSuccess == "N") {
        this.show4UnwipedSNo = true;
      } else {
        this.show4UnwipedSNo = false;
      }
    },
    //Change in Fifth Wipe
    changeFifthWipe() {
      if (this.fifthWipeSuccess == "N") {
        this.show5UnwipedSNo = true;
      } else {
        this.show5UnwipedSNo = false;
      }
    },
    //Change event of the upload
    onChange(e) {
      this.files = e.target.files || e.dataTransfer.files;
      if (!this.files.length) {
        this.dragging = false;
        return;
      } else {
        this.noFiles = false;
        if (this.files.length > 1) {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Max one file can be uploaded.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.noFiles = true;
          this.dragging = false;
          this.files = "";
        }
        for (let i = 0; i < this.files.length; i++) {
          this.createFile(this.files[i]);
        }
      }
    },
    //Checking file details
    createFile(file) {
      if (file.size > 10000000) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "File size is more than 10MB",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.noFiles = true;
        this.dragging = false;
        return;
      }
      this.file = file;
      this.dragging = false;
      this.fileList.push(file);
    },
    //Removing dragged file
    removeFile(name) {
      this.fileList = this.fileList.filter((x) => x.name !== name);
      if (!this.fileList?.length > 0) {
        this.noFiles = true;
        this.file = "";
        this.dragging = false;
      }
    },
    // to make sure user can only add number value
    isNumber(event) {
      let value = Utility.isNumber(event);
      return value;
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Change event in cos grade
    changeCosGrade() {
      if (this.selectedCosGrade !== "--- Select Grade ---") {
        this.disableSubmit = false;
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select Cosmetic Grade",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.disableSubmit = true;
      }
    },
    //Purge button click event
    async purgeAsset() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.axios
        .get("usb/purge_asset?UserID=" + this.userId + "&main_key=" + this.mainKey)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.$router.push({
              name: "USBAssetTracking",
              params: {
                submit: btoa("submitted"),
              },
            });
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Action Setter
    actionSetter(action) {
      if (this.actionList !== undefined && this.actionList !== null && this.actionList !== "") {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
  },
  components: {
    breadcrumbComp,
  },
};
